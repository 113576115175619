<template>
  <div class="container">
    <div class="video po-r">
      <video
        src="https://jixedu.oss-cn-hangzhou.aliyuncs.com/data/app/2021/12/03/1638501594151/1638501597529.mp4"
        controls
        poster="@/assets/classOne.png"
        preload
        ref="refVideo"
        id="refVideo"
        controlslist="nodownload"
        x-webkit-airplay="allow"
        webkit-playsinline="true"
        x5-playsinline="true"
        x5-video-player-type="h5"
        x5-video-player-fullscreen="false"
        playsinline="true"
        preload="auto"
        x5-video-orientation="h5"
        style="width: 100%; height: 100%; object-fit: fill"
      >
        此浏览器不支持video标签
      </video>
      <!-- <div
        class="play d-flex justify-content-center align-items-center p-a-c"
        v-show="playShow"
        @click="play"
      >
        <img src="@/assets/playB.png" alt="" />
      </div> -->
    </div>
    <div class="info">
      <div class="info-item co_333" v-for="(item, idx) in textList" :key="idx">
        <div class="label f-w700">{{ item.title }}</div>
        <div class="content">
          {{ item.content }}
        </div>
      </div>
    </div>

    <div v-show="showDown">
      <downBtn :isWx="isWx" @down="download" @wechatShow="wechatShow"></downBtn>
    </div>
  </div>
</template>

<script>
import downBtn from '@/components/downBtn'
import mixin from '@/utils/mixin'
export default {
  name: '',
  data() {
    return {
      showDown: false,
      textList: [
        {
          title: '了解韩语发音和写法',
          content:
            '学语言最重要的是发音，韩文是一门表音文字，即见字即发音。学会韩语基础的四十音非常的重要。学习完40音之后，就可以拼读出非常多韩文字了，即使你不明白意思，也能拼读出来。每个发音老师都会给出对应联想的记忆的方法，帮助大家快速记住发音和写法~',
        },
        {
          title: '熟练掌握韩语词汇',
          content:
            '并且韩语词汇当中有60%的汉字词是源于我们汉字，很多时候拼读出来之后也可以听出对应的中文。这对于我们记忆韩语单词又是非常大的助力呢。本节课将会带你走入韩语的世界，了解韩语的发音技巧，学习一部分的发音哦~',
        },
        {
          title: '喜欢韩语 学习韩国 ',
          content:
            '许许多多的同学喜欢韩国文化，喜欢韩国idol~喜欢韩语却不知道韩语其实除了兴趣以外，学习之后还可以给自己带来什么样的惊喜，有这样困惑的小伙伴，赶紧点开视频找寻答案吧',
        },
      ],
      playShow: true,
      video: null,
    }
  },
  mixins: [mixin],
  components: {
    downBtn,
  },

  created() {
    if (this.$route.query.isApp) {
      this.showDown = false
    } else {
      this.showDown = true
    }
  },
  mounted() {
    // 监听视频播放
    this.$refs.refVideo.addEventListener('play', () => {
      console.log('video is playing')
      this.playShow = false
    })
    // 监听视频暂停
    this.$refs.refVideo.addEventListener('pause', () => {
      console.log('video is stop')
      this.playShow = true
    })
  },
  watch: {
    video(n, o) {
      /*  console.log(n, o, 'ffff')
      n.play()
      if (n.play()) {
        this.playShow = false
        n.pause()
      } else {
        n.play()
        this.playShow = true
      } */
    },
  },
  methods: {
    play() {
      this.video = document.getElementById('refVideo')
      this.video.play()
    },
  },
}
</script>

<style lang="scss" scoped>
.video {
  width: 100%;
  margin-bottom: 29px;
  .play {
    width: 63px;
    height: 63px;
    background: #fff;
    border-radius: 50%;
    img {
      height: 25px;
      margin-left: 5px;
    }
  }
}
.info {
  padding: 0 16px;
  font-family: PingFang SC;
  &-item {
    margin-bottom: 24px;
    .label {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 8px;
    }
    .content {
      font-size: 14px;
      line-height: 160%;
      letter-spacing: -0.2px;
    }
  }
}
</style>
